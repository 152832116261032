export const APP_LANG_JP = {
  WELCOME_TO_APP: 'DChatアプリへようこそ',
  LOGIN: 'ログイン',
  FORGOT_PASSWORD: 'パスワードをお忘れの場合',
  ENTER_YOUR_EMAIL: 'メールアドレスを入力してください。',
  INPUT: {
    EMAIL: 'メール ID',
    PASSWORD: 'パスワード',
    FIRST_NAME: '名',
    LAST_NAME: '姓',
  },
  PASSWORD_RESET: {
    MESSAGE: 'パスワードはリセットされました。',
    NEW_PASSWORD_SEND: '受信トレイで新しいパスワードを確認してください。',
    GO_BACK_TO_LOGIN_PAGE: 'ログインページに戻る',
    PROBLEM: '何か問題があるようです。',
  },
  MENU: {
    PROFILE: 'プロフィール',
    STATUS: 'ステータス',
    LOGOUT: 'ログアウト',
  },
  STATUS: {
    ONLINE: 'オンライン',
    OFFLINE: '退席中',
    AWAY: 'オフライン',
  },
  PERSONAL_INFORMATION: 'プロフィール',
  CHANGE_PICTURE: '写真変更',
  SHARE: 'シェア',
  BUTTON: {
    OK: 'Ok',
    SAVE: '保存',
    CANCEL: 'キャンセル',
    SHARE: 'シェア',
    BACK: '戻る',
    SEND: '送信',
    REGISTER_USER: 'アカウントを作成',
    CREATE: '更新',
    DELETE: '삭제',
    UPDATE: 'アップデート',
  },
  CREATE_GROUP: 'グループ更新',
  UPDATE_GROUP: 'グループ更新',
  GROUP_NAME: 'グループ名',
  EDIT_PROFILE: 'プロフィール更新',
  PASSWORD: {
    CHANGE_PASSWORD: 'パスワード変更',
    CURRENT_PASSWORD: '現在のパスワード',
    NEW_PASSWORD: '新しいパスワード',
    NEW_PASSWORD_VERIFY: '新しいパスワード（再入力）',
  },
  INFORMATION: {
    SURNAME: '姓',
    NAME: '名',
    DATE_OF_BIRTH: '生年月日',
    GENDER: '性別',
    GENDER_MALE: '男性',
    GENDER_FEMALE: '女性',
    PHONE_NUMBER: '電話番号',
    EMAIL_ID: 'Email',
    ZIP_CODE: '郵便番号',
    ADDRESS: '住所',
    PREFECTURE: '住所',
    EMPLOYEE_NUMBER: 'ID',
    ORGANIZATION: '会社名',
    TITLE: '職種',
  },
  COMMUNICATION: 'コミュニケーション',
  SEARCH_BY_USER: '検索',
  SEARCH_BY_NAME: '検索',
  SEARCH_IN_CONVERSATION: 'メッセージを検索する',
  MEMO: 'メモ書き',
  MEMO_REMOVE: 'メモを削除',
  MEMO_REMOVE_CONFIRMATION: 'このメモを削除してもよろしいですか？',
  DESCRIPTION: '商品詳細',
  EDIT: '編集',
  ADD: '追加',
  ENTER_YOUR_MESSAGE_HERE: '新しいメッセージの入力',
  START_CHATTING_NOW: 'チャットを始めましょう！',
  MESSAGE: 'メッセージ',
  FIND: 'メッセージを検索',
  QUICK_MESSAGE_TABS: {
    STICKERS: 'ステッカー',
    EMOTIONS: '感情',
    GIFS: 'GIFS',
  },
  SEARCH_NAME: '名前検索',
  PRODUCT_CODE: '商品コード',
  MESSAGES_LABEL: 'メッセージ',
  MESSAGES: {
    NO_MATCHES: '一致するものはありません',
    NO_ID_MATCHES: 'ユーザーIDから検索してください。',
    NO_DATA_TO_DISPLAY: '現在、表示するデータはありません',
    NO_DATA_MESSAGE: 'ユーザーコードを入力して検索してください。',
  },
  ERROR: {
    INVALID_LOGIN: 'ユーザー名とパスワードが正しくありません。',
    INVALID_EMAIL: 'メールアドレスが正しくありません。',
    UPLOAD_FILES: 'ファイルのアップロードに失敗しました',
    FAILED_TO_CANCEL_UPLOAD: 'アップロードのキャンセルに失敗しました',
    SAVE_DESCRIPTION: '説明の保存に失敗しました',
    FETCH_PRODUCT: '商品の取得に失敗しました',
    MATCH_PASSWORD: 'パスワードが一致しません',
    UPDATE_PROFILE_IMAGE: 'プロフィール画像の更新に失敗しました',
    SOCKET_CONNECTION: 'ソケット接続に失敗しました',
    DEFAULT: '何かがうまくいかなかった',
    FAILED_TO_DOWNLOAD: 'ダウンロードに失敗',
    FAILED_TO_GET_CONTACTS: '連絡先の取得に失敗しました',
    FAILED_TO_DELETE_MEMO: '削除失敗しました。',
    FAILED_TO_UPDATE_MEMO: 'メモの更新に失敗しました。',
    FAILED_TO_FETCH_MEMOS: 'メモの取得に失敗しました。',
    FAILED_TO_CREATE_NEW_USER: 'アカウントを作成できません。',
    MISSING_FIELDS: 'フィールドが空です。',
  },
  SUCCESS: {
    WELCOME: 'ようこそ',
    UPDATE_DETAILS: 'プロフィールを更新しました。',
    SEE_YOU: 'ログアウトしました',
    CHECK_EMAIL_MESSAGE: 'メールをチェックしてパスワードを変更してください',
    CREATED_NEW_USER: 'アカウント作成のリクエストが送信',
  },
  MESSAGE_STATUS: {
    UNSEEN: '未読',
    SEEN: '見られる',
  },
  WELCOME: 'ようこそ',
  UNIQUE_NEED: '固有の問題の必要性',
  UNIQUE_SOLUTION: '独自のソリューション',
  NOTE_DESCRIPTION: {
    ADD: '説明を追加',
    ADD_TITLE: 'タイトルを追加',
  },
  PRIVACY_POLICY: 'Dental Management System (DMS) プライバシーポリシー',
  USER_POLICY: 'ユーザーポリシー',
  POLICY: 'ポリシー',
  MESSAGE_DELETE_GROUP: '削除します。',
};
