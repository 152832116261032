const config = {
  env: process.env.NODE_ENV,
  baseURI: process.env.REACT_APP_API_BASE_URI,
  socketURI: process.env.REACT_APP_SOCKET_URI,
  linkpreviewURI: process.env.REACT_APP_API_LINK_PREVIEW,
  endpoints: {
    // auth
    login: '/auth/login',

    // user
    self: '/user/get-user',
    updateSelf: '/user/update-profile',
    updatePicSelf: '/user/update-profile-image',
    signUp: '/signup/register',
    selfRemove: '/user/terminate-account',

    // memo
    memos: '/user-memo/list',
    createMemo: '/user-memo/create',
    updateMemo: '/user-memo/update/:id',
    deleteMemo: '/user-memo/delete/:id',

    // password
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    changePassword: '/user/change-password',

    // friends
    friends: '/friends/list',

    // friends
    contacts: '/contact/list',

    // group chat
    createGroup: '/chat-group/create-group',
    updateGroup: '/chat-group/update-group',
    deleteGroup: '/chat-group/delete-group',

    // products
    products: '/product/list',
    product: '/product/detail',
    productCount: '/product/get-product-notification-count',
    productRead: '/notification-read/product-read',

    // file
    uploadFile: '/message/upload-file',
    cancelUploadFile: '/message/cancel-upload',
    downloadFile: '/message/download-file/:fileName',
    viewFile: '/message/view-file/:fileName',

    // static message
    staticMessage: '/static-message/list',
  },
};

export default config;
