import React, { useState } from 'react';

const AdditionalOptions = ({ type, value, handleChange }) => {
  switch (type) {
    case 'organization':
      return (
        <div className="inputbox">
          <label className="col-form-label mr-3">住所</label>
          <input
            type="text"
            required
            value={value}
            onChange={e => handleChange({ address: e.target.value })}
            id="address"
          />
        </div>
      );
    case 'personal':
      return (
        <div className="inputbox">
          <label className="col-form-label mr-3">会社名</label>
          <input
            type="text"
            onChange={e => handleChange({ company_name: e.target.value })}
            value={value}
            id="company_name"
            required
          />
        </div>
      );
    default:
      return null;
  }
};

export default AdditionalOptions;
