import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  currentRoom: {},
  messageLoading: false,
});

export default function (state = initialState, action) {
  let currentRoom = Object.assign({}, state.get('currentRoom')) || {};

  switch (action.type) {
    case actionTypeConstants.CURRENT_CHAT_ROOM:
      currentRoom = { ...action.payload };

      return state.set('currentRoom', currentRoom);
    case actionTypeConstants.UPLOADED_FILES_IN_ROOM:
      currentRoom['files'] = action.payload;

      return state.set('currentRoom', currentRoom);
    case actionTypeConstants.ADD_FILE_IN_ROOM:
      currentRoom['files'] = currentRoom['files']
        ? currentRoom['files'].concat(action.payload)
        : [].concat(action.payload);

      return state.set('currentRoom', currentRoom);
    case actionTypeConstants.UPDATE_PARTICULAR_FILE_IN_ROOM:
      currentRoom['files'] = currentRoom.files.map(fl => {
        if (fl.original_name === action.payload.original_name) {
          return { ...fl, ...action.payload };
        } else {
          return fl;
        }
      });

      return state.set('currentRoom', currentRoom);

    case actionTypeConstants.REMOVE_FILE_IN_ROOM:
      currentRoom['files'] = currentRoom.files.filter(fl => fl.original_name !== action.payload.original_name);

      return state.set('currentRoom', currentRoom);

    case actionTypeConstants.UPDATE_ROOM_MESSAGE:
      currentRoom['currentMessage'] = action.payload;

      return state.set('currentRoom', currentRoom);
    case actionTypeConstants.MESSAGE_FETCH_IN_ROOM:
      return state.set('messageLoading', action.payload);
    default:
      return state;
  }
}
