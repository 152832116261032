import Helmet from 'react-helmet';
import React, { Suspense, lazy } from 'react';
import { ToastContainer } from 'react-toastify';
import { Router, Switch, Route } from 'react-router-dom';

import * as routes from 'constants/routes';
import history from 'utils/history';

import Spinner from 'components/common/Spinner';
import PrivateRoute from 'components/common/PrivateRoute';
import { APP_LANG_JP } from 'constants/lang';
import { useWindowSize } from './hooks/useWindowSize';
import GotoMobile from './common/goto-mobile/GotoMobile';
import SignUp from './sign-up';

// import Login from './login';

const Login = lazy(() => import('./login'));
const Dashboard = lazy(() => import('./dashboard'));
const ResetPassword = lazy(() => import('./reset-password'));
const Unsubscribe = lazy(() => import('./unsubscribe'));

// Top level application router.
const BaseRouter = () => {
  const size = useWindowSize();

  // Check for the unsubscribe path
  if (size.width <= 768 && !window.location.href.includes(routes.UNSUBSCRIBE)) {
    return <GotoMobile />;
  }

  return (
    <Router history={history}>
      <ToastContainer />
      <Helmet>
        <title>{APP_LANG_JP.WELCOME_TO_APP}</title>
      </Helmet>
      <></>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path={routes.LOGIN} component={Login} />
          <Route exact path={routes.SIGN_UP} component={SignUp} />
          <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
          <Route exact path={routes.UNSUBSCRIBE} component={Unsubscribe} />
          {/* <PrivateRoute path={routes.DASHBOARD} component={Dashboard} /> */}
          <PrivateRoute path={routes.DASHBOARD} component={Dashboard} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default BaseRouter;
