import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  staticMessages: [],
});

export default function (state = initialState, action) {
  let staticMessages = [];

  switch (action.type) {
    case actionTypeConstants.ADD_STATIC_MESSAGE:
      staticMessages = action.payload.data;

      return state.set('staticMessages', staticMessages);
    default:
      return state;
  }
}
