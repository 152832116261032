import Background from './images/bg.png';
import Logo from './images/icon.png';
import LoginBackground from './images/login-bg.png';
import DefaultProfilePic from './images/default-profile-pic.png';
import DefaultProductPic from './images/product_default.png';

import PlayStoreImage from './images/play-store.png';
import AppStoreImage from './images/apple-logo.png';

export { Logo, Background, LoginBackground, DefaultProfilePic, DefaultProductPic, PlayStoreImage, AppStoreImage };
