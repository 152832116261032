import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  chats: [],
  newChat: {},
});

export default function (state = initialState, action) {
  let chats = state.get('chats').slice() || [];

  const chatRoom = action.payload && action.payload.room ? action.payload.room : '';

  switch (action.type) {
    case actionTypeConstants.ADD_CHAT:
      return state.set('chats', action.payload);

    case actionTypeConstants.ADD_NEW_CHAT:
      chats.unshift(action.payload);

      return state.set('chats', chats);

    case actionTypeConstants.NEW_CHAT:
      return state.set('newChat', action.payload);

    case actionTypeConstants.ADD_CHAT_HISTORY:
      const chatRoomIdx = chats.findIndex(x => x.room === chatRoom);

      if (action.payload.data.length > 0) {
        const lastId = action.payload.lastId;

        if (!chats[chatRoomIdx]) {
          return state.set('chats', chats);
        }

        const messageExists = chats[chatRoomIdx].messages.find(
          msg => msg._id === lastId || msg.uid.toString() === lastId
        );

        if (messageExists) {
          return state.set('chats', chats);
        }
      }

      let newMessages = action.payload.data.reverse();

      let prevMessages = chats[chatRoomIdx].messages;

      let allMessages = newMessages.concat(prevMessages);

      chats[chatRoomIdx].lastId = action.payload.lastId;
      chats[chatRoomIdx].messages = allMessages.filter((v, i, a) => a.findIndex(t => t.uid === v.uid) === i);
      chats[chatRoomIdx].totalMessages = action.payload.total;

      return state.set('chats', chats);

    case actionTypeConstants.REMOVE_CHAT:
      return state.set('chats', chats);

    case actionTypeConstants.UPDATE_NEW_MESSAGE_COUNT_IN_CHATROOM:
      const crIdx = chats.findIndex(x => x.room === chatRoom);

      if (crIdx >= 0) {
        chats[crIdx].new_msg_count = 0;
      }

      return state.set('chats', chats);

    case actionTypeConstants.NEW_MESSAGE:
      const idx = chats.findIndex(x => x.room === chatRoom);

      if (idx < 0) {
        return state.set('chats', chats);
      }
      const msg = { ...action.payload };

      chats[idx].new_msg_count += 1;

      chats[idx].messages.push(msg);

      return state.set('chats', chats);
    case actionTypeConstants.MESSAGE_SEEN_STATUS:
      const value = action.payload[0];

      if (!value) return state.set('chats', chats);

      let msgx = chats.find(x => x.room === value.room)?.messages?.find(y => y._id === value._id);
      if (msgx) {
        msgx.seen = value.seen;
        return state.set('chats', chats);
      }
      return state.set('chats', chats);

    case actionTypeConstants.TOTAL_CHAT_INCREMENT:
      let croom = chats.find(x => x.room === chatRoom);

      croom.totalMessages = (parseInt(croom.totalMessages) + 1).toString();

      return state.set('chats', chats);

    case actionTypeConstants.REMOVE_NEW_MESSAGE_MARK_IN_CHAT:
      return state.set('chats', chats);

    default:
      return state;
  }
}
