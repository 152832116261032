import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  userInfo: {},
});

export default function (state = initialState, action) {
  let userInfo = {};

  switch (action.type) {
    case actionTypeConstants.ADD_USER_INFORMATION:
      userInfo = { ...action.payload };

      return state.set('userInfo', userInfo);
    case actionTypeConstants.CHANGE_USER_STATUS:
      userInfo = Object.assign({}, state.get('userInfo'));

      userInfo.login_status = action.payload;

      return state.set('userInfo', userInfo);
    case actionTypeConstants.UPDATE_MEMO:
      userInfo = Object.assign({}, state.get('userInfo'));

      userInfo.description = action.payload;

      return state.set('userInfo', userInfo);
    default:
      return state;
  }
}
