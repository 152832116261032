import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  references: [],
});

export default function (state = initialState, action) {
  let references = state.get('references').slice() || [];

  switch (action.type) {
    case actionTypeConstants.ADD_MESSAGE_REFERENCES:
      let foundReference = references.find(ref => ref.id === action.payload.id);

      if (foundReference) {
        foundReference.ref = action.payload.ref;

        return state.set('references', references);
      }

      references.push(action.payload);

      return state.set('references', references);
    case actionTypeConstants.REMOVE_MESSAGE_REFERENCES:
      return state.set('references', []);
    default:
      return state;
  }
}
