import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

import { LOGIN } from 'constants/routes';
import * as tokenService from 'services/token';

class PrivateRoute extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
    };
  }

  setIsLoggedIn = value => {
    this.setState({
      isLoggedIn: value,
    });
  };

  componentWillMount() {
    if (!tokenService.getAccessToken()) {
      this.setIsLoggedIn(false);
    } else {
      this.setIsLoggedIn(true);
    }
  }

  render() {
    const { isLoggedIn } = this.state;
    if (isLoggedIn) {
      return <Route {...this.props} />;
    } else {
      return <Redirect to={LOGIN} />;
    }
  }
}

export default withRouter(PrivateRoute);
