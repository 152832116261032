import http from 'utils/http';

import { OFFLINE } from 'constants/userStatus';
import { CHANGE_STATUS } from 'constants/events';

import * as tokenService from 'services/token';

import config from '../config';

import store from 'store';

import { LOGOUT } from 'constants/actions';

/**
 * POST Login
 *
 * @return { object }
 */

export async function login(user) {
  const data = await http.post(config.endpoints.login, user);

  return data;
}

/**
 * Action to clear user token
 *
 */
export async function logout() {
  tokenService.clear();

  let { socket } = await import('services/io');
  socket.emit(CHANGE_STATUS, { status: OFFLINE });
  socket.disconnect();

  store.dispatch({ type: LOGOUT });
  window.location.reload();
}
