import React from 'react';

import { PlayStoreImage, AppStoreImage } from 'assets/images';

import './style.css';

const GotoMobile = () => {
  return (
    <div className="wrapper">
      <div className="content_wrapper">
        <div className="heading">アプリをダウンロードする</div>
        <div className="app_download_group">
          <a className="download_btn" href="https://apps.apple.com/np/app/fdental/id1622415170" target={'_blank'}>
            <div className="download_btn_content">
              <img className="download_btn_content_img" src={AppStoreImage} />
              アップルストアへ
            </div>
          </a>
          <a
            className="download_btn"
            target={'_blank'}
            href="https://play.google.com/store/apps/details?id=com.fdental.app"
          >
            <div className="download_btn_content">
              <img className="download_btn_content_img" src={PlayStoreImage} />
              Playストアへ
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default GotoMobile;
