import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  products: [],
  count: 0,
  notifiedProducts: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypeConstants.ADD_PRODUCTS:
      return state.set('products', action.payload);
    case actionTypeConstants.COUNT_OF_PRODUCT:
      return state.set('count', action.payload);
    case actionTypeConstants.INCREASE_PRODUCT_COUNT:
      const listOfProducts = state.get('notifiedProducts').concat(action.payload);
      const setOfProducts = [...new Set(listOfProducts)];
      const newCount = setOfProducts.length;

      return state.set('count', newCount).set('notifiedProducts', setOfProducts);
    case actionTypeConstants.ADD_PRODUCT: {
      const products = [action.payload.data, ...state.get('products')];
      return state.set('products', products);
    }

    default:
      return state;
  }
}
