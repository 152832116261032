import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  chatSearchBar: false,
  notice: {
    notify: false,
    data: {},
  },
});

export default function (state = initialState, action) {
  let notice = Object.assign({}, state.get('notice')) || {};

  switch (action.type) {
    case actionTypeConstants.SHOW_CHAT_SEARCH_BAR:
      return state.set('chatSearchBar', action.payload);

    case actionTypeConstants.ON_RECEIVE_NOTIFICATION:
      notice.notify = true;
      notice.data = action.payload;

      return state.set('notice', notice);
    case actionTypeConstants.STOP_NOTIFICATION:
      notice.notify = false;
      notice.data = {};

      return state.set('notice', notice);
    default:
      return state;
  }
}
