export const ADD_CHAT = 'ADD_CHAT';
export const NEW_CHAT = 'NEW_CHAT';
export const ADD_NEW_CHAT = 'ADD_NEW_CHAT';
export const REMOVE_CHAT = 'REMOVE_CHAT';
export const ADD_CHAT_HISTORY = 'ADD_CHAT_HISTORY';
export const TOTAL_CHAT_INCREMENT = 'ADD_CHAT_NUMBER';
export const ADD_FOUND_IDS_WITH_KEYWORD = 'ADD_FOUND_IDS_WITH_KEYWORD';
export const REMOVE_FOUND_IDS_WITH_KEYWORD = 'REMOVE_FOUND_IDS_WITH_KEYWORD';
export const UPDATE_NEW_MESSAGE_COUNT_IN_CHATROOM = 'UPDATE_NEW_MESSAGE_COUNT_IN_CHATROOM';

export const ADD_NEW_MESSAGE_MARK_IN_CHAT = 'ADD_NEW_MESSAGE_MARK_IN_CHAT';
export const REMOVE_NEW_MESSAGE_MARK_IN_CHAT = 'REMOVE_NEW_MESSAGE_MARK_IN_CHAT';

export const ADD_SEARCH_TEXT = 'ADD_SEARCH_TEXT';
export const REMOVE_SEARCH_TEXT = 'REMOVE_SEARCH_TEXT';

export const ADD_MESSAGE_REFERENCES = 'ADD_MESSAGE_REFERENCES';
export const REMOVE_MESSAGE_REFERENCES = 'REMOVE_MESSAGE_REFERENCES';

export const NEW_MESSAGE = 'NEW_MESSAGE';
export const USER_STATUS_CHANGE = 'USER_STATUS_CHANGE';
export const MESSAGE_SEEN_STATUS = 'MESSAGE_SEEN_STATUS';

export const ADD_FRIENDS = 'ADD_FRIENDS';
export const ADD_NEW_FRIEND = 'ADD_NEW_FRIEND';
export const REORDER_FRIENDS = 'REORDER_FRIENDS';
export const CHANGE_FRIEND_STATUS = 'CHANGE_FRIEND_STATUS';
export const CHANGE_LAST_MESSAGE = 'CHANGE_LAST_MESSAGE';

export const ADD_CONTACTS = 'ADD_CONTACTS';

export const UPDATE_MEMO = 'UPDATE_MEMO';
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const ADD_USER_INFORMATION = 'ADD_USER_INFORMATION';

export const LOGOUT = 'LOGOUT';

export const SHOW_CHAT_SEARCH_BAR = 'SHOW_CHAT_SEARCH_BAR';

export const ADD_STATIC_MESSAGE = 'ADD_STATIC_MESSAGE';

export const CURRENT_CHAT_ROOM = 'CURRENT_CHAT_ROOM';
export const ADD_FILE_IN_ROOM = 'ADD_FILE_IN_ROOM';
export const REMOVE_FILE_IN_ROOM = 'REMOVE_FILE_IN_ROOM';
export const UPLOADED_FILES_IN_ROOM = 'UPLOADED_FILES_IN_ROOM';
export const UPDATE_PARTICULAR_FILE_IN_ROOM = 'UPDATE_PARTICULAR_FILE_IN_ROOM';
export const MESSAGE_FETCH_IN_ROOM = 'MESSAGE_FETCH_IN_ROOM';

export const UPDATE_ROOM_MESSAGE = 'UPDATE_ROOM_MESSAGE';

export const STOP_NOTIFICATION = 'STOP_NOTIFICATION';
export const ON_RECEIVE_NOTIFICATION = 'ON_RECEIVE_NOTIFICATION';

// Product actions
export const COUNT_OF_PRODUCT = 'COUNT_OF_PRODUCT';
export const INCREASE_PRODUCT_COUNT = 'INCREASE_PRODUCT_COUNT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
