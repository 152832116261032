import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  keywords: {},
  text: '',
});

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypeConstants.ADD_FOUND_IDS_WITH_KEYWORD:
      return state.set('keywords', action.payload);

    case actionTypeConstants.REMOVE_FOUND_IDS_WITH_KEYWORD:
      return state.set('keywords', {});

    case actionTypeConstants.ADD_SEARCH_TEXT:
      return state.set('text', action.payload);

    case actionTypeConstants.REMOVE_SEARCH_TEXT:
      return state.set('text', '');

    default:
      return state;
  }
}
