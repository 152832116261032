export const DASHBOARD = '/';

// Authentication Routes
export const LOGIN = '/login';
export const RESET_PASSWORD = '/reset-password';
export const SIGN_UP = '/sign-up';

// Application Menu Routes
export const CONTACTS = '/contacts';
export const NOTIFICATIONS = '/notifications';

// Products
export const PRODUCTS = '/products';
export const PRODUCT = '/products/:id';

// Admin messages
export const ADMIN_MESSAGES = 'admin-messages';
export const ADMIN_MESSAGE = 'admin-messages/:id';

// Chat related Routes
export const CHAT_WINDOW = '/chat';
export const CHAT_ROOM = '/chat/:roomId';
export const CHAT_ROOM_FILES = '/chat/:roomId/files';

// Others
export const PRIVACY_POLICY = '/privacy-policy';
export const USER_POLICY = '/user-policy';

// Unsubscribe
export const UNSUBSCRIBE = '/terminate-account';
