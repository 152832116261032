import { toast } from 'react-toastify';
import { messageStatus } from 'constants/messageStatus';
import { notificationDefaultMessage } from 'constants/messages';

export const toaster = (type, message) => {
  switch (type) {
    case messageStatus.SUCCESS:
      toast.success(message || notificationDefaultMessage.SUCCESS, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;
    case messageStatus.ERROR:
      toast.error(message || notificationDefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'error-toast toast-container',
        bodyClassName: 'toast-message',
      });
      break;
    case messageStatus.WARN:
      toast.warn(message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'warning-toast toast-container',
        bodyClassName: 'toast-message',
      });
      break;
    case messageStatus.INFO:
      toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'info-toast toast-container',
        bodyClassName: 'toast-message',
      });
      break;
    case messageStatus.NEW_MESSAGE:
      toast.info(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'info-toast toast-container',
        bodyClassName: 'toast-message',
      });
      break;
    default:
      toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'info-toast toast-container',
        bodyClassName: 'toast-message',
      });
  }
};
