import { combineReducers } from 'redux';

import userReducer from './userReducer';
import chatReducer from './chatReducer';
import roomReducer from './roomReducer';
import friendsReducer from './friendsReducer';
import contactsReducer from './contactsReducer';
import staticReducer from './staticReducer';
import componentReducer from './componentReducer';
import productReducer from './productReducer';
import chatSearchResultReducer from './chatSearchResultReducer';
import messageReferenceReducer from './messageReferenceReducer';

const rootReducer = combineReducers({
  chatReducer,
  friendsReducer,
  contactsReducer,
  roomReducer,
  userReducer,
  staticReducer,
  productReducer,
  componentReducer,
  chatSearchResultReducer,
  messageReferenceReducer,
});

export default (state, action) => rootReducer(action.type === 'LOGOUT' ? undefined : state, action);
