import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  friends: [],
});

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;

    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

  return arr;
}

export default function (state = initialState, action) {
  let friends = [];

  switch (action.type) {
    case actionTypeConstants.ADD_FRIENDS:
      const sortedList = action.payload.slice(0).sort(function (a, b) {
        return a.uid - b.uid;
      });

      friends.push(...sortedList);

      return state.set('friends', friends);

    case actionTypeConstants.ADD_NEW_FRIEND:
      friends = state.get('friends').slice() || [];

      friends.unshift(action.payload);

      return state.set('friends', friends);

    case actionTypeConstants.CHANGE_FRIEND_STATUS:
      friends = state.get('friends').slice() || [];

      const index = friends.findIndex(frn => frn.code === action.payload.code);

      if (index < 0) {
        return state.set('friends', friends);
      }

      friends[index].login_status = action.payload.login_status;

      return state.set('friends', friends);

    case actionTypeConstants.REORDER_FRIENDS:
      friends = state.get('friends').slice() || [];

      const idx = friends.findIndex(frn => frn.room === action.payload);

      const arrangedFriends = array_move(friends, idx, 0);

      return state.set('friends', arrangedFriends);

    case actionTypeConstants.CHANGE_LAST_MESSAGE:
      friends = state.get('friends').slice() || [];
      const i = friends.findIndex(frn => frn.room === action.payload.room);

      friends[i].last_message_rec = {
        last_message: action.payload.message,
        uid: action.payload.uid,
        sender: action.payload.sender,
      };

      return state.set('friends', friends);
    case actionTypeConstants.REMOVE_NEW_MESSAGE_MARK_IN_CHAT:
      friends = state.get('friends').slice() || [];

      const rIdx = friends.findIndex(frn => frn.room === action.payload.room);

      if (rIdx < 0) return state.set('friends', friends);

      friends[rIdx].new_msg_count = 0;

      return state.set('friends', friends);

    case actionTypeConstants.ADD_NEW_MESSAGE_MARK_IN_CHAT:
      friends = state.get('friends').slice() || [];

      const ridx = friends.findIndex(frn => frn.room === action.payload.room);

      if (ridx < 0) return state.set('friends', friends);

      friends[ridx].new_msg_count = friends[ridx].new_msg_count + 1;

      return state.set('friends', friends);
    default:
      return state;
  }
}
