import Immutable from 'immutable';

import * as actionTypeConstants from '../constants/actions';

let initialState = Immutable.Map({
  contacts: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypeConstants.ADD_CONTACTS:
      return state.set('contacts', action.payload);
    default:
      return state;
  }
}
