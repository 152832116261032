import axios from 'axios';

import config from '../config';

import * as authService from 'services/auth';
import * as tokenService from 'services/token';

const http = axios.create({
  baseURL: config.baseURI,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * HTTP request interceptor.
 */
http.interceptors.request.use(config => {
  const accessToken = tokenService.getAccessToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

/**
 * HTTP response interceptor
 */
http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const errorMessage = error && error.response && error.response.data.message ? error.response.data.message : '';
    const errorStatusCode = error && error.response && error.response.status ? error.response.status : '';

    if (errorStatusCode === 404 && errorMessage.includes('Invalid token.')) {
      authService.logout();

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default http;
