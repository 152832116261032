// receive
export const USER_ONLINE = 'user_online';

export const NEW_MESSAGE = 'new_message'; // this event can emit too
export const CHAT_HISTORY = 'chat_history';

export const STATUS_CHANGED = 'status_changed';

export const ERROR = 'error';

// emit
export const CHANGE_ROOM = 'change_room';
export const CHANGE_STATUS = 'change_status';
export const FETCH_OLD_MESSAGE = 'fetch_old_message';
export const MESSAGE_SEEN = 'message_seen';
export const SEARCH_RESULT = 'search_result';
export const SEARCH = 'search';
export const NEW_PRODUCT_ALERT = 'new_product_alert';
