import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { messageStatus } from 'constants/messageStatus';

import { createUser } from 'services/user';

import { toaster } from 'utils/toaster';

import Spinner from 'components/common/Spinner';
import { APP_LANG_JP } from 'constants/lang';

import AdditionalOptions from './AdditionalOptions';

const ACCOUNT_TYPE = {
  INDIVIDUAL: 'personal',
  ORGANIZATION: 'organization',
};

function SignUpForm() {
  const history = useHistory();

  const [isLoginBtnDisabled, disableLoginBtn] = useState(false);

  const [registerUser, setRegisterUser] = useState({
    account_type: ACCOUNT_TYPE.INDIVIDUAL,
  });

  const onChange = change => {
    setRegisterUser(prevUser => ({
      ...prevUser,
      ...change,
    }));
  };

  const placeholder =
    registerUser.account_type === ACCOUNT_TYPE.ORGANIZATION
      ? {
          firstName: 'マイ',
          lastName: '歯科クリニック',
        }
      : {};

  async function signUpAPI(e) {
    e.preventDefault();

    try {
      disableLoginBtn(true);
      if (registerUser.account_type === ACCOUNT_TYPE.ORGANIZATION) {
        delete registerUser.company_name;
      } else if (registerUser.account_type === ACCOUNT_TYPE.INDIVIDUAL) {
        delete registerUser.address;
      }

      await createUser(registerUser);
      toaster(messageStatus.SUCCESS, APP_LANG_JP.SUCCESS.CREATED_NEW_USER);
      setRegisterUser({});
    } catch (e) {
      toaster(messageStatus.ERROR, APP_LANG_JP.ERROR.FAILED_TO_CREATE_NEW_USER);
    } finally {
      disableLoginBtn(false);
    }
  }

  return (
    <React.Fragment>
      <form className="" onSubmit={signUpAPI}>
        <h3>{APP_LANG_JP.WELCOME}</h3>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '8px' }}>
          <div className="signup__option">
            <label className="col-form-label mr-3">個人アカウント</label>
            <input
              name="user-type"
              checked={registerUser.account_type === ACCOUNT_TYPE.INDIVIDUAL}
              type="radio"
              onClick={() => onChange({ account_type: ACCOUNT_TYPE.INDIVIDUAL })}
            />
          </div>
          <div className="signup__option">
            <label className="col-form-label mr-3">会社アカウント</label>
            <input
              name="user-type"
              type="radio"
              checked={registerUser.account_type === ACCOUNT_TYPE.ORGANIZATION}
              onChange={e => onChange({ account_type: ACCOUNT_TYPE.ORGANIZATION })}
            />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '8px' }}>
          <div className="inputbox">
            <label className="col-form-label mr-3">{APP_LANG_JP.INPUT.FIRST_NAME}</label>
            <input
              type="text"
              value={registerUser.first_name || ''}
              onChange={e => onChange({ first_name: e.target.value })}
              id="firstName"
              placeholder={placeholder.firstName}
              required
            />
          </div>
          <div className="inputbox">
            <label className="col-form-label mr-3">{APP_LANG_JP.INPUT.LAST_NAME}</label>
            <input
              type="text"
              value={registerUser.last_name || ''}
              onChange={e => onChange({ last_name: e.target.value })}
              id="lastName"
              placeholder={placeholder.lastName}
              required
            />
          </div>
        </div>
        <div className="inputbox">
          <label className="col-form-label mr-3">メールアドレス *</label>
          <input
            type="email"
            placeholder="hey@hello.com"
            value={registerUser.email || ''}
            onChange={e => onChange({ email: e.target.value })}
            id="staticEmail"
            required
          />
        </div>
        <AdditionalOptions
          type={registerUser.account_type}
          value={
            registerUser?.account_type === ACCOUNT_TYPE.ORGANIZATION ? registerUser.company_name : registerUser.address
          }
          handleChange={onChange}
        />
        <div className="submit-button">
          <button type="submit" className="submitbtn" disabled={isLoginBtnDisabled}>
            {isLoginBtnDisabled ? <Spinner size="small" /> : APP_LANG_JP.BUTTON.REGISTER_USER}
          </button>
        </div>
      </form>
      <div
        className="text-center"
        style={{ marginTop: '10px', cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() => history.push('/login')}
      >
        <i className="fa fa-arrow-left" aria-hidden="true"></i>
        {APP_LANG_JP.BUTTON.BACK}
      </div>
    </React.Fragment>
  );
}

export default SignUpForm;
