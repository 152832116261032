import * as storage from 'utils/storage';

import CONSTANTS from 'constants/appConstant';

const { ACCESS_TOKEN } = CONSTANTS;

/**
 * Set access token
 * @param {string} token
 */

export function setAccessToken(token) {
  storage.set(ACCESS_TOKEN, token);
}

/**
 * @return {string}
 */

export function getAccessToken() {
  return storage.get(ACCESS_TOKEN);
}

/**
 * Make local storage empty
 */

export function clear() {
  storage.clear();
}
