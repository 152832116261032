import React from 'react';

import { LoginBackground } from 'assets/images';

import SignUpForm from './SignUpForm';

function SignUp() {
  return (
    <div className="fdental-login">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 loginFormBox"></div>
          <div className="col-12 col-md-6 col-lg-6 loginbox">
            <div className="loginlogo d-flex align-content-center flex-wrap bd-highlight justify-content-center">
              <div className="welcomelogin">
                <img alt={'LoginBackground'} src={LoginBackground} />
              </div>
              <SignUpForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
