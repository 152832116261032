import axios from 'axios';
import http from 'utils/http';
import config from '../config';

/**
 * GET details of logged in user
 *
 * @return { object }
 */

export async function self() {
  const data = await http.get(config.endpoints.self);

  return data;
}

/**
 * POST updates details of the user
 *
 * @param { object } userData
 *
 * @return { object }
 */

export async function updateSelf(userData) {
  const data = await http.post(config.endpoints.updateSelf, userData);

  return data;
}

/**
 * POST Update picture of the user
 *
 * @param { object } userData
 *
 * @return { object }
 */

export async function updatePictureSelf(userData) {
  const data = await http.post(config.endpoints.updatePicSelf, userData);

  return data;
}

/**
 * POST Update picture of the user
 *
 * @param { object } desc
 *
 * @return { object }
 */

export async function updateMemo(desc) {
  const data = await http.post(config.endpoints.updateMemo, desc);

  return data;
}

/**
 *  User Register.
 *
 * @param {Object} payload
 *
 * @returns {Object}
 */
export const createUser = async payload => {
  const response = await http.post(config.endpoints.signUp, payload);
  return response;
};

export async function removeSelf(token) {
  try {
    console.log(token);
    const response = await axios.post(
      config.endpoints.selfRemove,
      {},
      {
        baseURL: config.baseURI,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log('Unsubscribe successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in unsubscribe:', error);
    throw error; // Rethrow the error if you want calling code to handle it
  }
}
